<template>
    <div class="productSee">
		<NewsHeader :data="data"></NewsHeader>
        <div class="model-info">
            <img class="info-image" :src="productInfo.img" alt="" />
            <div class="info-right">
                <div class="info-title line-one">{{productInfo.title}}</div>
                <div class="info-line"></div>
                <div class="model">{{lang.product_norms}}：{{ productInfo.description }}</div>
                <!-- <div class="info-btn">
                    <div class="btn">
                        <el-button plain style="margin-right: 5.94rem;">上一篇：手撕鸡柳</el-button>
                        <el-button plain>下一篇：黄豆焖猪手</el-button>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="info-text">
            <div class="recommend" >
                <div class="info-list">
                    <div class="info-item bold" style="width: 100%;">{{lang.product_for_you_recommend}}</div>
                </div>
                <div class="img">
                    <div class="tuijian" v-for="(item,index) in recommendList" :key="index" @click="path('/productplatform/see/'+ item.id)">
                        <img class="recommend-image" :src="item.img" alt="" />
                        <p>{{item.title}}</p>

                    </div>
                </div>
            </div>
            <div class="image-text">
                <div class="info-list">
                    <div :class="(activeNum== 0 ? 'select ' : ' ')+'info-item'" @click="active(0)">{{lang.product_detail}}</div>
                    <div :class="(activeNum== 1 ? 'select ' : ' ')+'info-item'" @click="active(1)">{{lang.product_spec}}</div>
                    <div :class="(activeNum== 2 ? 'select ' : ' ')+'info-item'" @click="active(2)">{{lang.product_package}}</div>
                </div>
                <div class="linered"></div>
                <div class="image-list" v-show="activeNum == 0" v-html="productInfo.info"></div>
                <!-- 详细信息 -->
                <div class="image-list" v-show="activeNum == 1" v-html="productInfo.specs"></div>
                <!-- 包装 -->
                <div class="image-list" v-show="activeNum == 2" v-html="productInfo.package"></div>
            </div>
        </div>
        <div class="foot"></div>
    </div>
</template>

<script>
	import NewsHeader from '@/components/news/NewsHeader.vue';
import { getProductInfo } from '@/api/store.js'
import {
		mapState
	} from 'vuex'
	export default {
		name: "productSee",
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			})
		},
		data() {
			return {
				id: this.$route.params.id,
				data:{
					name:'产品展示',
					name2:'产品详情',
					url:'/productplatform/index/'
				},
                productInfo:{},
                recommendList:[],
                activeNum:0,
			}
		},
		components:{
			NewsHeader,
		},
        beforeRouteUpdate(to, from, next) {
            this.id = to.params.id
			this.getProductInfoFun()
            next();
        },
		created() {
			this.getProductInfoFun()
			this.data.name=this.lang.product_show
			this.data.name2=this.lang.product_detail_page
		},
		methods:{
			getProductInfoFun(){
				// 获取信息
                let data = {
                    id:this.id
                }
                this.productInfo = {}
                this.recommendList = []
				this.data = {
					name:'产品展示',
					name2:'产品详情',
					url:'/productplatform/index/'
				}
				getProductInfo(data).then(res=>{
					this.productInfo = res.data.product_info
                    this.data.url = this.data.url + res.data.product_info.cate_id
					this.recommendList = res.data.recommend_list
				})
			},
            active(num){
                this.activeNum = num
            },
			path(routePath){
				 this.$router.push(routePath); 
			}
		}


	}
</script>

<style lang="less" scoped>
::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover{
    color: #B60914;
}
::v-deep .el-breadcrumb__inner.is-link:hover, .el-breadcrumb__inner a:hover{
    color: #B60914;
}
    .productSee{
        width: 75rem;
        margin: 0 auto;
        .model-info{
            width: 100%;
            margin-bottom: 3.19rem;
            display: flex;
            justify-content: space-between;
            .info-image{
                width: 23.75rem;
                height: 23.75rem;
            }
            .info-right{
                width: 48.31rem;
                height: 23.75rem;
                .info-title{
                    font-weight: bold;
                    font-size: 1.5rem;
                    margin-top: 0.88rem;
                }
                .info-line{
                    height: 0.06rem;
                    background: #E5E4E4;
                    margin: 0.81rem 0;
                }
                .model{
                    font-size: 0.88rem;
                }
                .info-btn{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    .btn{
                        margin-top: 1.5rem;
                    }
                }
            }
        }
        .info-text{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .recommend{
                width: 11rem;
                .img{
                    width: 100%;
                    padding: 1.06rem 0.69rem 0;
                    border: 0.06rem solid #F2F2F2;
                    .tuijian{
                        p{
                            margin: 0.2rem 0 0.5rem;
                            font-size: 0.88rem;
                            text-align: center;
                        }
                        .recommend-image{
                            width: 9.63rem;
                            height: 9.63rem;
                        }
                    }
                }
            }
            .image-text{
                width: 62.5rem;
                margin: 0 auto;
                .image-list{
                    width: 100%;
                    margin: 2.5rem 0;
                    text-align: center;
                    .content-image{
                        width: 49.38rem;
                        height: auto;
                    }
                }
                .linered{
                    width: 62.56rem;
                    height: 0.13rem;
                    background-color: #B60914;
                }
            }
        }
        .foot{
            width: 100%;
            height: 10rem;
        }
    }
    .info-list{
        width: 100%;
        height: 2.88rem;
        display: flex;
        background: #F2F2F2;
        align-items: center;
        .info-item{
            width: 9.13rem;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .info-item:hover{
            background-color: #fafafa;
        }
        .select{
            background-color: #B60914 !important;
            color: #fff;
        }
    }
	/* 响应式布局 */
	@media (max-width: 1220px) {
		.productSee {
		    width: 100%;
		    padding: 0 1rem;
		}
		.productSee .model-info .info-right {
		    width: 68%;
		    height: 23.75rem;
			padding-left: 2%;
		}
		.productSee .info-text .image-text{
		    width: 82%;
		}
		.productSee .info-text .image-text .image-list /deep/ img{
			max-width: 85% !important;
		}
		
	}
	@media (max-width: 768px) {
		.productSee .model-info,.productSee .info-text {
		    display: block;
		}
		.productSee .model-info .info-image {
		    width: 100%;
		}
		.productSee .model-info .info-right {
		    width: 100%;
		    height: auto;
		    padding: 1rem;
		}
		.productSee .info-text .recommend,.productSee .info-text .image-text{
		    width: 100%;
		}
		.productSee .info-text .recommend .img .tuijian{
			width: 50%;
			float: left;
			text-align: center;
			margin-bottom: 1.5rem;
		}
		.productSee .info-text .recommend .img .tuijian .recommend-image {
		    width: 94%;
		    height: 20.63rem;
			
		}
	}
	@media (max-width: 540px) {
		.productSee .info-text .recommend .img .tuijian .recommend-image {
		        height: 14.63rem;
		}
	}
	@media (max-width: 430px) {
		.productSee .info-text .recommend .img .tuijian .recommend-image {
		        height: 10.63rem;
		}
	}
</style>