<template>
	<div>
		<div class="header container-secondary-main">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: data.url }">{{data.name}}</el-breadcrumb-item>
				<el-breadcrumb-item >{{data.name2}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			data: {
				type: Object,
				default: ""
			}
		},
	}
</script>

<style lang='less'>
	.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover {
	    color: #B60914;
	}
	.header {
		padding: 8.5rem 0 1.5rem;
		display: flex;
		align-items: center;

		.header-image {
			margin-right: 0.5rem;
			width: 1.31rem;
			height: 1.31rem;
		}
		
	}
	.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover {
	    font-weight: bold;
	}
	.el-breadcrumb__inner.is-link, .el-breadcrumb__inner a {
	    font-weight: normal;
	}
	.el-breadcrumb__separator {
	    color: #303133;
		font-weight: normal;
	}
</style>